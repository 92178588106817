.main {
	font-family: "Poppins", sans-serif;
	background-color: #5476ff;

	.header__logos{
		padding: 1rem;
	}
	.background__img{
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 0;
		// @media only screen and (max-width: 700px) {
		// 	top: 304px;
		// }
	}
}

.failed {
	background-color: #013172;
	min-height: 100vh;

	.header__logos{
		padding: 5rem 0 0  5rem;
		@media only screen and (max-width: 600px) {
			padding: 20px 0 0  20px;
		}
	}
	.failed_content {
		padding-left: 5rem;
		margin-top: 100px;
		@media only screen and (max-width: 600px) {
			padding-left: 20px;
			margin-top: 70px;
			text-align: center;
		}
		.failed_title {
			font-weight: 700;
			font-size: 35px;
			color: #ffffff;
			@media only screen and (max-width: 600px) {
				font-size: 25px;
			}
		}
		.failed_subtitle {
			font-weight: 700;
			font-size: 30px;
			margin-top: 50px;
			color: #f19413;
			font-style: italic;
			@media only screen and (max-width: 600px) {
				font-size: 22px;
				text-align: center;
				margin-top: 20px;
				margin: 20px 30px;
				border-top: 1px solid grey;
				padding-top: 15px;
			}
		}
	}
	.failed_image {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 60%;

		@media only screen and (max-width: 600px) {  
			position: absolute; 
			width: 100%;
			background: white;
			margin-top: -18px;
		}
	}
}

.success {
	background-color: #fff;
	min-height: 100vh;

	.content {
		background-color: #013172;

		.header__logos{
			padding: 5rem 0 0  5rem;
			@media only screen and (max-width: 600px) {
				padding: 20px 0 0  20px;
			}
		}
		.success_content {
			// padding-left: 5rem;
			margin-top: 20px;
			padding-bottom: 100px;
			@media only screen and (max-width: 600px) {
				margin-top: 70px;
				text-align: center;
			}
			.success_title {
				text-align: center;
				font-weight: 700;
				font-size: 45px;
				color: #f19413;
				@media only screen and (max-width: 600px) {
					font-size: 25px;
				}
			}
			.success_subtitle {
				text-align: center;
				font-weight: 700;
				font-size: 30px;
				margin-top: 5px;
				color: #ffffff;
				border-bottom: 1px solid grey;
				width: max-content;
				margin: 5px auto 20px;
				@media only screen and (max-width: 600px) {
					width: 75%;
					font-size: 22px;
					text-align: center;
					margin-top: 20px;
					margin: 20px auto;
					padding-top: 15px;
				}
			}
			.success_desc {
				text-align: center;
				margin-top: 25px;
				.desc_title {
					font-size: 15px;
					color: #6f8cb9;
					@media only screen and (max-width: 600px) {

					}
				}
				.desc_subtitle {
					font-size: 25px;
					font-weight: 500;
					color: #ffffff;
					@media only screen and (max-width: 600px) {
						font-size: 20px;
						font-weight: 500;
						margin: 0px 10px;
					}
	
				}
			}
		}


	}
	.success_image {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 60%;

		@media only screen and (max-width: 600px) {  
			position: absolute; 
			width: 100%;
			background: white;
			margin-top: -18px;
		}
	}
	.institutes {
		width: 100%;
		min-height: 50%;
		background-color: white;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}
}

.insti_button {
	padding: 20px;
	.insti_logo_section {
		width: 100px;
		height: 75px;
		display: flex;
		align-items: center;
		margin: auto;
		.insti_logo {
			max-width: 100px;
			max-height: 75px;
		}
	}
	.insti_title {
		text-align: center;
		font-weight: 700;
		font-size: 20px;
		color: #f19413;
	}

	.insti_subtitle {
		font-size: 10px;
		color: #000000;
	}
	.insti_amount {
		font-size: 30px;
		font-weight: 700;
		color: #000000;
	}

	@media only screen and (max-width: 600px) { 
		padding: 5px;
		.insti_logo_section {
			width: 75px;
			height: 50px;
			.insti_logo {
				max-width: 75px;
				max-height: 50px;
			}
		}
		.insti_title {
			font-size: 12px;
		}
	
		.insti_subtitle {
			font-size: 7px;
		}
		.insti_amount {
			font-size: 20px;
			font-weight: 700;
			color: #000000;
		}
	}
}


.steps {
	background-color: #013172;
	min-height: 100vh;

	display: flex;
	flex-direction: row;

	@media only screen and (max-width: 600px) {
		flex-direction: column;
	}

	.back_icon {
		color: #013172;
		position: absolute;
		left: 30px;
		top: 30px;
		font-size: 40px;
		cursor: pointer;

		@media only screen and (max-width: 600px) {
			color: #ffffff;
			font-size: 30px;
			right: 30px;
			top: 22px;
		}
	}
	
	.propelld__logo {
		position: absolute;
		right: 30px;
		top: 30px;

		@media only screen and (max-width: 600px) {
			position: relative;
			right: auto;
			top: auto;
			margin: 20px 0 0 70px;
		}
	}

	.left_container {
		display: flex;
		background-color: #ffffff;
		height: 100%;
		min-height: 100vh;
		min-width: 35%;
		@media only screen and (max-width: 600px) {
			min-height: 100px;
			background-color: #013172;
			color: #ffffff;
			height: max-content;
			width: 100%;
		}

		// @media only screen and (max-width: 600px) {

		// }

		.left_content {
			margin: auto;
			text-align: center;
			padding: 20px;

			.steps_logo {
				max-width: 200px;
				max-height: 100px;
				margin-bottom: 40px;
				@media only screen and (max-width: 600px) {
					display: none;
				}
			}
	
			.steps_title {
				font-weight: 700;
				font-size: 40px;
				color: #013172;
				margin-top: 20px;
				text-transform: lowercase;
				text-transform: capitalize;
				@media only screen and (max-width: 600px) {
					margin-top: 50px;
					color: #ffffff;
				}
			}
	
			.steps_subtitle {
				font-weight: 700;
				font-size: 24px;
				color: #013172;
				@media only screen and (max-width: 600px) {
					font-size: 24px;
					color: #ffffff;
					margin-top: 5px;
				}
			}

			.arrow_sign {
				margin: 50px auto;
				@media only screen and (max-width: 600px) {
					display: none;
				}
				
			}
	
			.steps_desc {
				margin-bottom: 25%;
				.desc_title {
					font-weight: 700;
					font-size: 25px;
					color: grey;
					@media only screen and (max-width: 600px) {
						margin-top: 30px;
						font-size: 20px;
						color: #f19413;
					}
				}
				.amount {
					font-weight: 700;
					font-size: 35px;
					color: grey;
					@media only screen and (max-width: 600px) {
						font-size: 30px;
					}
				}
			}

		}
	}

	.right_container {
		display: flex;
		width: 100%;

		@media only screen and (max-width: 600px) {
			background-color: #ffffff;
			padding-bottom: 50px;
		}
		
		
		.steps_section {
			background-color: #ffffff;
			border-radius: 10px;
			margin: auto;
			width: 80%;
			padding: 30px 15px;
			
			.step {
				font-weight: 700;
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 10px;
				.count {
					font-size: 22px;
					min-width: 100px;
					width: auto;
					color: #f19413;
				}
				.details {
					font-size: 18px;
				}
				.extra {
					font-size: 10px;
				}
			}


			@media only screen and (max-width: 600px) {
				margin: -50px auto auto;
				background-color: #ffffff;
				box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
				padding: 15px;
				.step {
					.count {
						font-size: 15px;
						width: auto;
						min-width: 60px;
					}
					.details {
						font-size: 12px;
					}
					.extra {
						font-size: 8px;
					}
				}
			}
			.contact_section {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				margin: 10px;
				color: #f19413;
				font-size: 18px;
				font-weight: 700;
				@media only screen and (max-width: 600px) {
					align-items: center;
					justify-content: center;
					font-size: 15px;
				}
				p {
					margin-right: 20px;
					align-self: center;
				}

			}
		}
	}

	.header__logos{
		padding: 5rem 0 0  5rem;
		@media only screen and (max-width: 600px) {
			padding: 20px 0 0  20px;
		}
	}
}
